import React from "react";

const Home = () => {
	return (
		<div className={'Home'}>
			
			<header className={'App-header'}>
				Test Home
			</header>
		
		</div>
	);
}
export default Home;