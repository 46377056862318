import React, { useEffect, useState } from "react";
import { TVChartContainer } from '../components/TVChartContainer/index';
import { DPDOWN } from '../components/DPDOWN/index';
import { version } from '../charting_library';
import 'jquery/src/jquery';
import 'bootstrap/dist/css/bootstrap.css';

import 'popper.js/dist/umd/popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.js';

const Bourse = () => {
	const [dropTitle, setDropTitle] = useState("Watchlist");

	

	return (
		<div className={'App'}>


			<section id="sp-main-body">

				<div className="container">
					<div className="container-inner">


						<div className="row">

							<main id="sp-component" className="col-lg-12 ">
								<div className="sp-column ">
									<div id="system-message-container" aria-live="polite" ></div>



									<div id="senioriz-watchlist" >

									<DPDOWN type="bourse"/>

									

										<div className="modal" tabIndex="-1" id="listNameModal">
											<div className="modal-dialog">
												<div className="modal-content">
													<div className="modal-header">
														<h5 className="modal-title">Save List</h5>
														<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
													</div>
													<div className="modal-body">
														<form id="save-list-form">
															<div className="form-group">
																<label htmlFor="save-list-name">List Name:</label>
																<input type="text" className="form-control" id="save-list-name" name="list-name" required="" />
															</div>
														</form>

													</div>
													<div className="modal-footer">
														<button type="button" className="btn btn-primary" id="submit-list-name">Save</button>
													</div>
												</div>
											</div>
										</div>

										<div className="modal" tabIndex="-1" id="loadListModal">
											<div className="modal-dialog">
												<div className="modal-content">
													<div className="modal-header">
														<h5 className="modal-title">Load List</h5>
														<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
													</div>
													<div className="modal-body">
														<select id="list-selection" className="form-select">
														</select>
														<button type="button" className="btn btn-danger mt-2" id="delete-selected-list">Delete</button>
													</div>
													<div className="modal-footer">
														<button type="button" className="btn btn-primary" id="load-selected-list">Load</button>
													</div>
												</div>
											</div>
										</div>







									</div>
									<TVChartContainer />

								</div>
							</main>
						</div>
					</div>
				</div>

			</section>

		</div>
	);
}


export default Bourse;