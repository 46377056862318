import React, { useEffect, useState } from "react";
import axios from 'axios';

const user_id = 102;
const base_url = "https://panel.chartshield.com/server";

export const DPDOWN = (params, props) => {
    const [dropTitle, setDropTitle] = useState("Watchlist");

    function createCategory() {
        $("#myModal2").modal("show")

    }



    async function saveCategory() {
        $(".alarmMsg2").html("")
        await axios.post(`${base_url}/addCategory.php`, {
            user_id: user_id,
            type: params.type,
            text: $(".namex").val(),
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.data.status == 1) {



                    $(".name").val("")
                    $("#myModal2").modal("hide")
                    $(`.dm2 .dropdown-item.mf`).remove()
                    res.data.data.forEach((el) => {

                        var symbolDiv_ = `<li cs="${el.id}"><button cs="${el.id}" class="dropdown-item mf" type="button" >${el.text}</button></li>`;
                        $('#dropdown-menu-2')
                            .prepend(symbolDiv_);
                        $(`.dm2 .dropdown-item:not('.df')`).click(function () {
                            $("#dropdownMenuButton2").text($(this).text())
                            $("#dropdownMenuButton2").attr("cs", $(this).attr("cs"))
                            $(`#symbols > div`).hide()
                            $(`#symbols > div[cs=${$(this).attr("cs")}]`).show()
                        })


                    })

                } else {
                    $(".alarmMsg2").html(res.data.msg)
                }

            });
    }
    return (
        <>
            <div className="logoPanel">
                <img src="https://chartshield.com/wp-content/uploads/2024/06/%DB%B4%DB%B3%DB%B4%DB%B2%DB%B4%DB%B3%DB%B2%DB%B4.webp" />
            </div>
            <div className="btnSidePanel">
                <span className="itemList" onClick={() => { setDropTitle('Watchlist') }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" width="40" height="40"><path fill="currentColor" d="M13 11h18a2 2 0 0 1 2 2v17a2 2 0 0 1-2 2H13a2 2 0 0 1-2-2V13c0-1.1.9-2 2-2Zm18-1H13a3 3 0 0 0-3 3v17a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3V13a3 3 0 0 0-3-3Zm-2 11H15v1h14v-1Zm-14-5h14v1H15v-1Zm14 10H15v1h14v-1Z"></path></svg>
                </span>
                <span className="itemList" onClick={() => { setDropTitle('Alarms') }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" width="40" height="40"><path fill="currentColor" d="M35 14.66 29.16 9l-.7.72 5.84 5.66.7-.72ZM9 14.66 14.84 9l.7.72-5.84 5.66-.7-.72ZM22 16v7h-5v1h6v-8h-1Z"></path><path fill="currentColor" fill-rule="evenodd" d="M22 33a11 11 0 1 0 0-22 11 11 0 0 0 0 22Zm0-1a10 10 0 1 0 0-20 10 10 0 0 0 0 20Z"></path></svg>
                </span>
            </div>
            <div className="dropdown senioriz-ww">

                <div className="d-flex align-items-center justify-content-between">

                    {/* <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle btn-tradingview" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="true">
                            {dropTitle}
                        </button>
                    </div> */}



                </div>

                <div style={{ display: dropTitle != 'Watchlist' ? 'none' : '' }}>
                    <div className="alarmsTop">
                        <div className="name">
                            <div className="dropdown" style={{ display: dropTitle != 'Watchlist' ? 'none' : '' }}>
                                <button cs="0" className=" dropdown-toggle btn-tradingview" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="true">
                                    Default
                                </button>
                                <ul className="dropdown-menu dm2" id="dropdown-menu-2" aria-labelledby="dropdownMenuButton" data-popper-placement="bottom-start">

                                    <li cs="0"><button cs="0" className="dropdown-item" type="button">Default</button></li>
                                    <li><button className="dropdown-item df" type="button" onClick={() => { createCategory() }}>Create New</button></li>
                                </ul>

                            </div>
                        </div>
                        <div className="addAlert" onClick={() => { createCategory() }}>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M13.9 14.1V22h1.2v-7.9H23v-1.2h-7.9V5h-1.2v7.9H6v1.2h7.9Z"></path></svg>
                            </span>
                        </div>
                    </div>
                    <div id="symbols">

                    </div>
                </div>

                <div style={{ display: dropTitle != 'Alarms' ? 'none' : '' }}>
                    <div className="alarmsTop">
                        <div className="name">
                            <span>
                                Alerts
                            </span>
                            <span className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="18" height="18"><path fill="currentColor" fill-rule="evenodd" d="M3.5 4H5V2h1v2h1.5c.28 0 .5.22.5.5v9a.5.5 0 0 1-.5.5H6v2H5v-2H3.5a.5.5 0 0 1-.5-.5v-9c0-.28.22-.5.5-.5Zm.5 9V5h3v8H4Zm6.5-7H12V4h1v2h1.5c.28 0 .5.22.5.5v5a.5.5 0 0 1-.5.5H13v2h-1v-2h-1.5a.5.5 0 0 1-.5-.5v-5c0-.28.22-.5.5-.5Zm.5 5V7h3v4h-3Z"></path></svg>
                                {params.alarmCount}
                            </span>
                        </div>
                        <div className="addAlert" onClick={() => ($("#myModal").modal("show"))}>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M13.9 14.1V22h1.2v-7.9H23v-1.2h-7.9V5h-1.2v7.9H6v1.2h7.9Z"></path></svg>
                            </span>
                        </div>
                    </div>
                    <div id="alarms">

                    </div>
                </div>


                <div className="modal" id="myModal2" style={{ direction: "rtl" }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h4 className="modal-title">ایجاد واچ لیست</h4>
                            </div>

                            <div className="modal-body" style={{ textAlign: "right" }}>
                                <p>
                                    جهت ایجاد واچ لیست، نام دلخواه ان را وارد کنید
                                </p>
                                <div>
                                    <label style={{ display: "block" }}>نام</label>
                                    <input className="namex" placeholder="" style={{
                                        borderRadius: '10px',
                                        paddingLeft: '20px',
                                        marginBottom: '20px',
                                        width: '150px',
                                        direction: 'ltr',
                                        color: 'white',
                                        background: '#2a2e39',
                                        border: 0,
                                        height: '35px'
                                    }} />
                                </div>

                                <div style={{ color: "red" }} className='alarmMsg2'></div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-success" onClick={() => { saveCategory() }}>ثبت</button>
                                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={() => { $("#myModal2").modal("hide") }}>بستن</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>




    );
}

